import React from 'react';

const AboutsUs = () => {
    return (
        <section id="about-us" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="row gutter-width-sm">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="title">
                            <h2 className="text-uppercase">Getting you DeFi boys looking right, and just in time!</h2>
                        </div>
                    </div>
                </div>

                <div className="row gutter-width-sm justify-content-end">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div className="description">
                            <p>Today we are all proud of where DeFi has landed, but let's face it: We've all been ape-ing non-stop to get here. We forgot to look good doing it! You need to turn your swag on.</p>
                        </div>
                    </div>
                </div>
                <div className="row gutter-width-sm justify-content-end" style={{ marginTop: '80px' }}>
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div className="description">
                            <p>You can shop by picking up an NFT from us on <a href="https://opensea.io/collection/nocenterstyle/">OpenSea</a>. Sign a message containing your mailing address, and E-mail the message/signature to <a href="mailto:doll@nocenter.style">ship@nocenter.style</a><br /><br />When you purchase any of our NFTs, we ship to your door!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutsUs;
