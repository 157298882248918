import React from 'react';

const ContactsUs = () => {
    return (
        <section id="contact-us" className="block">
            <div className="bg-blue text-tertiary spacer p-top-xl p-bottom-xl">
                <div className="wrapper text-center">
                    <div className="title">
                        <h2 className="text-uppercase">You can contact us</h2>
                    </div>

	    	    <p>No Center Style LLC<br />3139 W. Holcombe Blvd Suite 2216<br />Houston, TX 77025</p>
	    		<p><a href="tel:+1-832-323-2386">tel:+1-832-323-2386</a><br /><a href="fax:+1-281-407-7498">fax:+1-281-407-7498</a></p>
	    	
	    		
                </div>
            </div>
        </section>
    );
};

export default ContactsUs;
